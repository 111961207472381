<template>
  <section class="memberorder-wrapper">
    <card title="会员信息">
      <section>
        <vipInfo :tableData="vipData"></vipInfo>
      </section>
    </card>
    <card title="订单信息">
      <el-table :data="memberList" border>
        <el-table-column
          label="序号"
          align="center"
          type="index"
          width="80"
        ></el-table-column>
        <el-table-column label="商品" align="center">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.opLinks" :key="index"
              >{{ index + 1 }}. {{ item.pName }}<br
            /></span>
          </template>
        </el-table-column>
        <el-table-column
          label="金额"
          align="center"
          prop="money"
        ></el-table-column>
        <el-table-column
          label="订单状态"
          align="center"
          prop="status"
        ></el-table-column>
        <el-table-column
          label="物流公司"
          align="center"
          prop="type"
        ></el-table-column>
        <el-table-column
          label="运单号"
          align="center"
          prop="waybill"
        ></el-table-column>
        <el-table-column
          label="订单号"
          align="center"
          prop="oid"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="110">
          <template slot-scope="scope">
            <el-button size="mini" @click="viewDetails(scope.$index, scope.row)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="PageSize"
        layout="total, prev, pager, next,jumper"
        :total="totalCount"
        style="margin: 20px 0 0 30px;"
      >
      </el-pagination>
    </card>
  </section>
</template>

<script>
import card from "../../components/card";
import vipInfo from "../../components/vipInfo";
export default {
  components: {
    card,
    vipInfo
  },
  data() {
    return {
      vipData: [
        { key: "手机号", value: "" },
        { key: "姓名", value: "" },
        { key: "床位", value: "" },
        { key: "宝宝生日", value: "" },
        { key: "宝宝体重", value: "" },
        { key: "地区", value: "" },
        { key: "默认地址", value: "" }
      ],
      memberList: [],
      currentPage: 1,
      // 默认每页显示的条数（可修改）
      PageSize: 10,
      totalCount: 0,
      uid: 0
    };
  },
  methods: {
    viewDetails(index, row) {
      this.$router.push({
        name: "checkorder",
        query: { uid: row.uid, oid: row.oid }
      });
    },
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage = val;
      this.searchOrder();
    },
    searchOrder() {
      this.$axios({
        url: "/back/order/order/getOrderByUId",
        params: {
          page: this.currentPage,
          uid: this.uid
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.PageSize = res.data.data.pageSize;
          this.totalCount = res.data.data.total;
          this.memberList = res.data.data.list;
        } else {
          this.$message({
            type: "error",
            message: res.data.msg
          });
        }
      });
    },
    getUserInfo() {
      this.$axios({
        url: "/back/user/getUser",
        params: {
          uId: this.uid
        }
      }).then(res => {
        if (res.data.code == 200) {
          this.vipDataFormmate(res.data.data);
        } else {
          this.$message({
            type: "error",
            message: res.data.msg
          });
        }
      });
    },
    vipDataFormmate(data) {
      const keyMap = ["tel", "uname", "bed", "birthday", "weight", "address"];
      this.vipData.forEach((item, index, arr) => {
        if (index < 5) {
          if (index === 3) {
            const bir = new Date(data[keyMap[index]]);
            arr[index].value =
              bir.getFullYear() +
              "年" +
              (bir.getMonth() + 1) +
              "月" +
              bir.getDate() +
              "日";
          } else arr[index].value = data[keyMap[index]];
        } else {
          let area = data[keyMap[5]].province + data[keyMap[5]].city;
          if (!area) {
            area = "";
          }
          arr[index].value = index === 5 ? area : data[keyMap[5]].address;
        }
      });
    }
  },
  created() {
    this.$openLoading();
    this.uid = this.$route.query.uid;
    Promise.all([this.searchOrder(), this.getUserInfo()]).then(res => {
      setTimeout(() => {
        this.$openLoading().close();
      }, 200);
    });
  }
};
</script>

<style></style>
